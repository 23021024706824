<template>
  <OrderTable :orderVehicles="orderVehicles"></OrderTable>
  <a-card :bodyStyle="{padding:50}">
    <template #title>
      车型信息修改
    </template>
    <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
      <a-row :gutter="16">
        <a-col :span="18">
          <a-form-item label="车型">
            <a-cascader v-model:value="form.brands" placeholder="请选择" :options="carBrands" :show-search="{ filter }" @change="change"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因">
            <a-textarea v-model:value="reason" :rows="6"
                        :maxlength="1000"
                        showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-button type="primary" @click="submitText">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { carBrandTree } from '@/api/system/setting'
import { message } from 'ant-design-vue'
import { onMounted } from '@vue/runtime-core'
import OrderTable from './OrderTable.vue'
export default {
  components: {
    OrderTable
  },
  props: { orderId: { type: String, default: '' }, subType: {},orderVehicles: {
      type: Array,
      default: []
    }, },
  emits: ['detail'],
  setup (props, { emit }) {
    const state = reactive({
      loading: false,
      carBrands: [],
      reason: '',
      form: {
        orderId: props.orderId
      }
    })
    const editParam = {
      params: state.form,
      type: {
        label: '订单修改',
        value: 0
      },
      subType: props.subType
    }
    const change = (value,e) => {
      [state.form.brandId,state.form.modelId]=value
      state.form.brandTxte = e[0].label + ' ' + e[1].label
    }
    const submitText = () => {
      let orderVehicleIds = []
      props.orderVehicles.forEach(item => {
        if (item.checked) {
          orderVehicleIds.push(item.orderVehicleId)
        }
      })
      if (orderVehicleIds.length === 0) {
        return message.error('请选择车辆')
      }
      state.form.orderVehicleIds = orderVehicleIds
      orderPickEdit({
        params: state.form,
        type: {
          value: 0
        },
        reason: state.reason,
        subType: props.subType
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.form.brands = undefined
          emit('success', false)
        }
      })
    }
    const loadData = () => {
      carBrandTree({}).then(res => {
        state.carBrands = res.data
      })
    }
    onMounted(loadData)
    return {
      ...toRefs(state),
      editParam,
      submitText,
      loadData,
      change
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}
.not-archive {
  color: #999;
}
</style>
